<template>
  <q-header elevated>
    <q-toolbar>
      <q-toolbar-title class="q-my-xs">
        <q-img
          :src="
            settings.logo_url ??
            'https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/logo/rgb_improvers_white.png'
          "
          style="max-width: 150px"
        ></q-img>
      </q-toolbar-title>
    </q-toolbar>
  </q-header>
  <div>
    <div
      class="full row items-center justify-center q-pa-xs"
      v-if="!landingAvailable"
    >
      <div class="col-12 q-pa-lg">
        <message :messageType="availabilityMessage"></message>
      </div>
    </div>
    <div
      class="full row items-center justify-center q-pa-xs"
      style="width: 75%; margin: auto"
      v-if="landingAvailable"
    >
      <div
        v-for="offer in offers"
        :key="offer.record_id"
        :class="colSize"
        class="q-pa-lg"
      >
        <q-card>
          <q-card-section class="q-pa-md row justify-between">
            <div class="q-pt-xs" style="margin: auto">
              <q-img
                :src="offer.logo_url"
                style="max-width: 150px; min-width: 150px; max-height: 32.5px"
                class="q-ma-auto"
              >
                <template v-slot:error>
                  <div
                    class="absolute-full flex flex-center text-h5 bg-primary"
                  >
                    {{ offer.provider }}
                  </div>
                </template>
              </q-img>
            </div>
          </q-card-section>

          <q-card-section class="q-pa-md">
            <q-list class="q-mb-xs row">
              <q-item class="col-6 text-center">
                <div style="width: 100%">
                  <div class="text-subtitle1">Per maand:</div>
                  <div
                    class="text-weight-medium"
                    :class="colSize == 'col-12' ? 'text-h6' : 'text-h5'"
                  >
                    {{
                      parseFloat(offer.installment_amount).toLocaleString(
                        "nl-NL",
                        {
                          style: "currency",
                          currency: "EUR",
                          maximumFractionDigits: 2,
                          useGrouping: false,
                        }
                      )
                    }}
                  </div>
                </div>
              </q-item>
              <q-item class="col-6 text-center">
                <div style="width: 100%">
                  <div class="text-subtitle1">Per jaar:</div>
                  <div
                    class="text-weight-medium"
                    :class="colSize == 'col-12' ? 'text-h6' : 'text-h5'"
                  >
                    {{
                      parseFloat(
                        offer.installment_amount_yearly
                      ).toLocaleString("nl-NL", {
                        style: "currency",
                        currency: "EUR",
                        maximumFractionDigits: 2,
                        useGrouping: false,
                      })
                    }}
                  </div>
                </div>
              </q-item>
            </q-list>
          </q-card-section>

          <q-separator inset />

          <q-card-section class="q-pa-md">
            <q-list
              style="min-height: 80px; max-height: 80px"
              class="q-mt-xs"
              dense
            >
              <q-item class="q-py-none consumption-item">
                <q-item-section
                  class="text-black text-weight-bold q-pa-none"
                  :class="
                    colSize == 'col-12' ? 'text-subtitle2' : 'text-subtitle1'
                  "
                  side
                  >{{ offer.product_title }}</q-item-section
                >
              </q-item>

              <q-item
                class="q-py-none consumption-item text-grey-9"
                v-show="offer.incentive !== 'Geen'"
              >
                <q-item-section
                  class="q-pa-none"
                  :class="colSize == 'col-12' ? 'text-subtitle1' : 'text-h6'"
                  ><div v-html="offer.incentive"></div
                ></q-item-section>
              </q-item>
            </q-list>
          </q-card-section>
          <q-separator inset />
          <q-card-section class="q-pa-md">
            <q-btn
              label="Bekijk aanbieding"
              :disable="offer.model == 'Error'"
              @click="offer.model = true"
              color="accent"
              size="25px"
              padding="none"
              class="col-12 full-width"
              no-caps
            ></q-btn>
          </q-card-section>
          <q-dialog persistent v-model="offer.model" full-height full-width>
            <div>
              <iframe
                :src="offer.landing_url"
                width="100%"
                height="100%"
                frameborder="0"
                class="bg-white"
                v-show="offer.landing_url !== 'Error'"
              >
              </iframe>
              <q-btn
                icon="close"
                style="position: absolute; right: 10px; top: 10px"
                @click="offer.model = false"
                color="secondary"
                size="85%"
                push
                no-caps
              />
            </div>
          </q-dialog>
        </q-card>
      </div>
    </div>
  </div>
  <q-footer class="bg-primary">
    <q-btn
      label="Privacy"
      type="a"
      :href="settings.privacy_url"
      target="_blank"
      flat
      color="grey-4"
    ></q-btn>
  </q-footer>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useQuasar } from "quasar";
import Message from "../components/Landing/Message.vue";
export default {
  components: {
    Message,
  },
  setup() {
    const $store = useStore();
    const $q = useQuasar();

    // Recieve data from store
    const landingAvailable = computed(() => {
      let available = $store.getters.landingContent["error"] ?? "";
      console.log(available);
      if (available == "") {
        console.log("available");
        return true;
      }
      console.log("unavailable");
      return false;
    });
    const availabilityMessage = computed(() => {
      if (landingAvailable.value) {
        return "";
      }
      return $store.getters.landingContent["error"];
    });
    const offers = computed(() => {
      if (!landingAvailable.value) {
        return [];
      }
      return $store.getters.landingContent;
    });
    const settings = computed(() => {
      return $store.getters.projectSettings;
    });

    // Check data recieved
    offers.value.forEach((value) => {
      value.model = false;
      if (!typeof value.project_title || value.project_title == "") {
        value.project_title = value.provider;
      }
      // if (!typeof value.incentive || value.incentive == "") {
      //   value.incentive = false;
      // }
      if (!typeof value.landing_url || value.landing_url == "") {
        value.installment_amount = "Err..";
        value.landing_url = "Error";
        value.model = "Error";
      }
      if (!typeof value.logo_url || value.logo_url == "") {
        value.logo_url = "Error";
      }
      if (
        !typeof value.installment_amount ||
        value.installment_amount == "" ||
        value.installment_amount == 0
      ) {
        value.installment_amount = "Err..";
        value.model = "Error";
      }
      if (
        !typeof value.installment_amount_yearly ||
        value.installment_amount_yearly == "" ||
        value.installment_amount_yearly == 0
      ) {
        value.installment_amount_yearly = "Err..";
        value.model = "Error";
      }
    });

    // Check what size the screen has
    const screenSize = computed(() => {
      return $q.screen.name;
    });

    // Set the size of colSize to the size of the screen
    const colSize = computed(() => {
      switch (screenSize.value) {
        case "md":
          return "col-6";
        case "sm":
          return "col-11";
        case "xs":
          return "col-12";
        default:
          return "col-4";
      }
    });

    return {
      landingAvailable,
      availabilityMessage,
      offers,
      colSize,
      settings,
    };
  },
};
</script>
